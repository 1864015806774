import React, { useEffect, useState, useContext, useMemo } from "react";
import { Bar, Line } from "react-chartjs-2";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from "react-router-dom";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { returnBackButton } from "../../../utils/helperfunctions";
import { LoginContext } from "../../../App";
import { backgroundColors, colors } from "../../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokSoundStatistics = () => {
  const { soundId } = useParams(),
    { selectedDays, setSelectedDays } = useContext(LoginContext),
    [userStatistics, setUserStatistics] = useState([]),
    [soundGainLoss, setSoundGainLoss] = useState([]),
    [loading, setLoading] = useState(true),
    [sortConfig, setSortConfig] = useState({ key: null, direction: null }),
    [error, setError] = useState(null);

  useEffect(() => {
    const fetchSoundStatistics = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      const from = moment(selectedDays[0]).format("YYYY-MM-DD");
      const to = moment(selectedDays[1]).format("YYYY-MM-DD");
      try {
        const response = await axiosInstance.post(
          "/api/tiktok/sounds/details",
          {
            sound_id: soundId,
            days: null,
            from,
            to,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserStatistics(response.data.data);
        setSoundGainLoss(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch TikTok user statistics");
        setLoading(false);
      }
    };

    fetchSoundStatistics();
  }, [soundId, selectedDays]);

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };
  const sortedVideos = useMemo(() => {
    if (!sortConfig.key) return userStatistics;

    const sortedData = [...userStatistics];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [userStatistics, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };
  const chartData = {
    labels: userStatistics.map((stat) =>
      moment(stat.date).format("DD MMM, YY")
    ),
    datasets: [
      {
        label: "Total Videos",
        data: userStatistics.map((stat) => stat.total_users),
        borderColor: backgroundColors.light_yellow,
        backgroundColor: colors.yellow,
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: true,
        // text: "TikTok Sound Statistics",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
    },
  };

  const optionsBar = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        type: "linear",
        position: "left",
        beginAtZero: true,
      },
      x: {
        type: "category",
        labels: soundGainLoss.map((item) =>
          moment(item.date).format("DD MMM, YY")
        ),
      },
    },
  };

  const generateChartData = () => {
    const labels = soundGainLoss.map((item) =>
      moment(item.date).format("DD MMM, YY")
    );
    const positiveValues = soundGainLoss.map((item) =>
      item.gained >= 0 ? item.gained : 0
    );
    const negativeValues = soundGainLoss.map((item) =>
      item.loss >= 0 ? item.loss : 0
    );

    const data = {
      labels,
      datasets: [
        {
          label: "Daily Videos Gained",
          data: positiveValues,
          borderColor: colors.yellow,
          backgroundColor: backgroundColors.light_yellow,
          tension: 0.4,
          fill: true,
          pointBackgroundColor: colors.yellow,
          pointBorderColor: "#fff",
          pointBorderWidth: 2,
          borderWidth: 2,
          yAxisID: "y",
        },
        {
          label: "Daily Videos Loss",
          data: negativeValues,
          borderColor: colors.red,
          backgroundColor: backgroundColors.red,
          tension: 0.4,
          fill: true,
          pointBackgroundColor: colors.red,
          pointBorderColor: "#fff",
          pointBorderWidth: 2,
          borderWidth: 2,
          yAxisID: "y",
        },
      ],
    };
    return data;
  };

  return (
    <div className="container tiktok-sound-statistics-container">
      <CommonHeader dashboardName={"TikTok Sound Statistics"} />
      {loading ? (
        <CommonLoader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <DaysFilter selectedDays={selectedDays} onChange={onChange} />

          <div className="chart-container mt-4">
            <div className="row">
              <div className="col-md-6">
                <Line data={chartData} options={options} />
              </div>
              <div className="col-md-6">
                <Bar data={generateChartData()} options={optionsBar} />
              </div>
            </div>
          </div>

          <div className="table-container mt-4">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSort("date")}
                  >
                    Date &nbsp;
                    {getArrow("date")}
                  </th>
                  <th>Total Videos</th>
                </tr>
              </thead>
              <tbody>
                {sortedVideos.map((stat, index) => (
                  <tr key={index}>
                    <td>{moment(stat.date).format("YYYY-MM-DD")}</td>
                    <td>{stat.total_users}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default TikTokSoundStatistics;
