import { IoChevronBack } from "react-icons/io5";
import moment from "moment";

export const returnBackButton = (size = 2) => {
  return (
    <IoChevronBack
      style={{ fontSize: `${size}rem`, cursor: "pointer" }}
      onClick={() => window.history.back()}
    />
  );
};
