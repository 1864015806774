import React, { useEffect, useRef } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import TikTokStats from "../pages/component/TikTokStats";
import UserCount from "../pages/component/UserCount";
import TopTrendingVideos from "./component/TopTrendingVideos";
import TikTokFollowerGrowth from "./component/TikTokFollowerGrowth";
// import TikTokEngagementMetrics from "./component/TikTokEngagementMetrics";
import TikTokHashtagPerformance from "./component/TikTokHashtagPerformance";
import TikTokIndividualHashtagPerformance from "./component/TikTokIndividualHashtagPerformance";
import TotalVideosMetrix from "./component/TotalVideosMetrix";
import EffectivenessMetrix from "./component/EffectivenessMetrix";
import ViewsAvgLineMetrix from "./component/ViewsAvgLineMetrix";
import InteractionsAvgLineMetrix from "./component/InteractionsAvgLineMetrix";
import { roles } from "../../../utils/constants";
import TikTokSoundStatistics from "./component/TikTokSoundStatistics";
import TikTokFollowersPerDay from "./component/TikTokFollowersPerDay";
import axiosInstance from "../../../refershTokenManage/axiosConfig";

const DashboardIndex = () => {
  const role = localStorage.getItem("userRole"),
    hasRole = roles.includes(role),
    hasAdminRole = role === "super-admin" || role === "admin",
    hasClientRole = role === "client",
    hasShownToast = useRef(false);

  const getTikToks = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(`/api/tiktoks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          client_id: Number(localStorage.getItem("client_id")),
        },
      });

      if (response.data?.tiktok.length) {
        if (!hasShownToast.current) {
          toast.success("Dashboard loaded successfully!");
          hasShownToast.current = true;
        }
      } else {
        if (!hasShownToast.current) {
          toast.error("You have not added any TikTok accounts. Set up your first one now!");
          hasShownToast.current = true;
        }
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        if (!hasShownToast.current) {
          toast.error("You have not added any TikTok accounts. Set up your first one now!");
          hasShownToast.current = true;
        }
      }
    }
  };

  useEffect(() => {
    if (hasClientRole) {
      getTikToks();
    }
  }, [hasClientRole]);

  return (
    <div className="userLists">
      <CommonHeader dashboardName={"Dashboard"} />

      <div className="row">
        {hasAdminRole && (
          <>
            <div className="col-md-12">
              <UserCount />
            </div>
            <hr className="mt-4" />
          </>
        )}

        {hasRole && (
          <>
            <div className="col-md-12">
              <InteractionsAvgLineMetrix />
            </div>
            <hr className="mt-4" />
          </>
        )}
      </div>

      {hasRole && (
        <>
          <div className="row">
            <div className="col-md-12">
              <TopTrendingVideos />
            </div>
            <hr className="mt-4" />
            <div className="col-md-12">
              <TikTokFollowerGrowth />
            </div>
            <hr className="mt-4" />
            <div className="col-md-12">
              <TikTokFollowersPerDay />
            </div>
            <hr className="mt-4" />
          </div>

          <div className="row">
            {/* <div className="col-md-12">
              <TikTokEngagementMetrics />
            </div> 
            <hr className="mt-4" /> */}
            <div className="col-md-12">
              <TikTokHashtagPerformance />
            </div>
            <hr className="mt-4" />
          </div>

          <div className="row">
            <div className="col-md-12">
              <TikTokIndividualHashtagPerformance />
            </div>
            <hr className="mt-4" />
            <div className="col-md-12">
              <TotalVideosMetrix />
            </div>
            <hr className="mt-4" />
          </div>

          <div className="row">
            <div className="col-md-12">
              <EffectivenessMetrix />
            </div>

            <hr className="mt-4" />

            <div className="col-md-12">
              <ViewsAvgLineMetrix />
            </div>

            <hr className="mt-4" />
          </div>

          <div className="row">
            <div className="col-md-12">
              <TikTokSoundStatistics />
            </div>
          </div>
        </>
      )}

      {hasClientRole && (
        <>
          <div className="row">
            <div className="col-md-12">
              <TikTokStats />
            </div>
          </div>
        </>
      )}
      <Toaster position="top-center" richColors />
    </div>
  );
};

export default DashboardIndex;
