import React, { useEffect, useState, useRef } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import CommonLoader from "../../../loader/CommonLoader";
import MetrixCards from "./userListingMetrix/MetrixCards";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link, useParams } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { SlUserFollowing } from "react-icons/sl";
import { GiShadowFollower } from "react-icons/gi";
import { MdFavorite } from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { returnBackButton } from "../../../utils/helperfunctions";
import { DateRangePicker } from "rsuite";
import moment from "moment";

const TikTokUserDetails = () => {
  const [pageStart, setPageStart] = useState(1),
    [isLoading, setIsLoading] = useState(false),
    [userNotFound, setUserNotFound] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    { tiktokId } = useParams(),
    [userDetails, setUserDetails] = useState({}),
    [userStats, setUserStats] = useState({}),
    [date, setDate] = useState([]);

  const handleChange = (record) => {
    if (!record || !record?.length) {
      getUserDetails(true, ["", ""]);
      setDate([]);
      return;
    }

    const formatDate = [record[0], record[1]];

    setDate(formatDate);
    getUserDetails(true, formatDate);
  };

  const getUserDetails = async (selectedDates = false, dates) => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading

    let startDate = selectedDates ? dates[0] : date[0];
    let endDate = selectedDates ? dates[1] : date[1];

    if (startDate) {
      startDate = moment(startDate).format("YYYY-MM-DD");
    } else {
      startDate = "";
    }

    if (endDate) {
      endDate = moment(endDate).format("YYYY-MM-DD");
    } else {
      endDate = "";
    }

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Conditionally add params if selectedDates is true
      if (selectedDates) {
        config.params = {
          recorded_at_from: startDate,
          recorded_at_to: endDate,
        };
      }

      const response = await axiosInstance.post(
        `/api/tiktok/user-details`,
        {
          tiktok_id: tiktokId,
        },
        config
      );

      if (response?.status === 200) {
        const { tikTokUserdetails, tikTokUserStatistics } =
          response?.data?.data;
        setUserDetails(selectedDates ? [] : tikTokUserdetails);
        setUserStats(
          selectedDates ? response?.data?.data : tikTokUserStatistics
        );
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
    setIsLoading(false); // End loading
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  return (
    <div className="userLists">
      {userNotFound.status === 403 ? (
        <div
          style={{
            fontSize: "30px",
            position: "fixed",
            top: "50%",
            left: "60vh",
          }}
          className="user-not-found-message container"
        >
          {userNotFound.message}
        </div>
      ) : (
        <>
          <div className="metrix">
            <MetrixCards
              cards={[
                {
                  icon: <SlUserFollowing size={40} color="#FF5722" />,
                  title: "Follower Count",
                  amount: userDetails?.follower_count,
                  link: "",
                },
                {
                  icon: <GiShadowFollower size={40} color="#9C27B0" />,
                  title: "Following Count",
                  amount: userDetails?.following_count,
                  link: "",
                },
                {
                  icon: <MdFavorite size={40} color="#4CAF50" />,
                  title: "Total Favorited",
                  amount: userDetails?.total_favorited,
                  link: "",
                },
                {
                  icon: <BiSolidVideos size={40} color="#673AB7" />,
                  title: "Visible Video Count",
                  amount: userDetails?.visible_videos_count,
                  link: `/Tiktok-UsersDetails/${tiktokId}/videos`,
                },
              ]}
            />
          </div>
          <div className="mt-1">
            <div className="userFetchData">
              <div className="userDetails mt-2">
                <div
                  style={{ backgroundColor: "#fff" }}
                  className="d-flex rounded-2 align-items-center p-1"
                >
                  {returnBackButton()}
                  {userDetails?.avatar && (
                    <img
                      src={userDetails?.avatar}
                      alt="avatar"
                      className="rounded-circle me-3"
                      style={{
                        width: "70px",
                        height: "70px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  {userDetails?.nickname && (
                    <h4>
                      <a
                        href={userDetails?.tiktok_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {userDetails?.nickname}
                      </a>
                    </h4>
                  )}

                  <DateRangePicker
                    style={{ marginLeft: "30px", maxHeight: "50px" }}
                    value={date}
                    onChange={handleChange}
                    placeholder="Select Date Range"
                  />
                </div>
                <div className="details mt-3">
                  <table className="user-table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Followers</th>
                        <th>Followings</th>
                        <th>Total Favorites</th>
                        <th>Visible Videos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="6">Loading...</td>
                        </tr>
                      ) : userStats?.length ? (
                        userStats?.map((user, index) => (
                          <tr
                            key={user?.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#f2f2f2",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{user?.follower_count}</td>
                            <td>{user?.following_count}</td>
                            <td>{user?.total_favorited}</td>
                            <td>{user?.visible_videos_count}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No Record found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {totalPages > 1 && (
                <div className="pagination">
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageStart={pageStart}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default TikTokUserDetails;
