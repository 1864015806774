import React, { useRef } from "react";

const FullscreenWrapper = ({ children }) => {
  const containerRef = useRef(null);

  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    const container = containerRef.current;
    if (container) {
      // Check if fullscreen is supported and enter fullscreen mode
      if (!document.fullscreenElement) {
        if (container.requestFullscreen) {
          container.requestFullscreen().catch((err) => {
            console.error(`Error enabling full-screen mode: ${err.message}`);
          });
        } else if (container.webkitRequestFullscreen) {
          /* Safari */
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          /* IE11 */
          container.msRequestFullscreen();
        }
      } else {
        document.exitFullscreen();
      }
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        cursor: "pointer",
      }}
      onClick={toggleFullScreen}
    >
      {children}
    </div>
  );
};

export default FullscreenWrapper;
