import React, { useContext, useState } from "react";
import SignIn from "./sign-in/SignIn";
import SignUp from "./sign-up/Signup";
import { LoginContext } from "../../App";



const Login = () => {

  const {createUser} = useContext(LoginContext)

  return (
      <div>{createUser ? <SignUp /> : <SignIn />}</div>
  );
};

export default Login;
