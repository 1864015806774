import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import DaysFilter from "./DaysFilter";
import ErrorPage from "../../common-components/error";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CommonLoader from "../../../../loader/CommonLoader";
import { colors } from "../../../../utils/constants";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserStats = () => {
  const [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [counts, setCounts] = useState({}),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);

    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/dashboard",
        { days: null, from, to },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCounts(response.data.counts);
      if (!Object.keys(response.data.counts).length) {
        setNoData(true);
      }
      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDays]);

  const data = {
    labels: [
      "Super Admins",
      "Admins",
      "Users",
      "Clients",
      "Freelancers",
      "Account Managers",
      "TikTok Accounts",
    ],
    datasets: [
      {
        label: "Counts",
        data: [
          counts.super_admins || 0,
          counts.admins || 0,
          counts.users || 0,
          counts.clients || 0,
          counts.freelancers || 0,
          counts.account_managers || 0,
          counts.tiktok_accounts || 0,
        ],
        backgroundColor: [
          colors.pink,
          colors.blue,
          colors.light_yellow,
          colors.sea_green,
          colors.purple,
          colors.yellow,
          colors.sky,
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: false,
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 29), new Date()]);
    else setSelectedDays(dates);
  };

  return (
    <div className="col-md-12">
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />
      {loading ? (
        <CommonLoader />
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <ErrorPage refetch={fetchData} />
      ) : (
        <div className="chart-wrapper">
          {Object.keys(counts) ? (
            <>
              <h5 className="chart-title">
                Total Users and TikTok Accounts &nbsp;
                <span
                  data-tooltip-id="help-tooltip"
                  style={{ fontSize: "26px" }}
                >
                  <IoIosHelpCircleOutline />
                </span>
                <ReactTooltip
                  id="help-tooltip"
                  place="bottom"
                  content="Helping Text"
                />
              </h5>
              <FullscreenWrapper>
                <Bar data={data} options={options} />
              </FullscreenWrapper>
            </>
          ) : (
            <p>No data available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default UserStats;
