export const clientHeadings = [
  "S.No",
  "Name",
  "Email",
  "Phone",
  "Address",
  "Created By",
  "Tiktok",
  "Instagram",
  "Settings",
  "Actions",
],
  userVideoDetails = [
    "Video ID",
    "Sound ID",
    "Video Url",
    "Views",
    "Likes",
    "Shares",
    "Saved",
    "Comments",
    "Video Create Date",
    "Attach Tag",
    "Details",
    "Tag",
  ],
  videoDetails = [
    "views",
    "likes",
    "comments",
    "shares",
    "saved",
    "created_at",
    "updated_at",
  ],
  soundDetailsHeadings = [
    "id",
    "User Count",
    "Sound ID",
    "Created At",
    "Updated At",
  ],
  roles = ["super-admin", "admin", "client", "freelancer", "account-manager"],
  colors = {
    pink: "#FF6384", // likes
    blue: "#36A2EB", // views
    sea_green: "#4BC0C0", // comments
    yellow: "#FF9F40", // shares
    light_yellow: "#FFCE56", // shares border
    purple: "#9966FF", // saved
    sky: "#CCCCFF",
    red: "#FF0000",
    black: "#000000",
    gray: "#808080",
    silvar: "#C0C0C0",
  },
  backgroundColors = {
    light_pink: "#FF99A6",
    light_blue: "#6CBDF0",
    light_sea_green: "#80D6D6",
    light_yellow: "#FFB763",
    lighter_yellow: "#FFDB8D",
    light_purple: "#B399FF",
    light_sky: "#cce0ff",
    red: "#FF0000",
  };
