import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alert from "react-bootstrap/Alert";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../common-components/customModal";

const userData = {
  name: "",
  email: "",
  phone: "",
  password: "",
  address: "",
  tiktok: "",
  status: "",
  id: "",
};
const settingData = {
  visible: false,
  tiktok: 0,
  instagram: 0,
  clientId: 0,
};
const AddTiktokAccounts = () => {
  const { user } = useContext(LoginContext),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [editedStatus, setEditedStatus] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [newUser, setNewUser] = useState(userData),
    [list, setList] = useState([]),
    [show, setShow] = useState(false),
    [edit, setEdit] = useState(""),
    [settings, setSettings] = useState(settingData),
    [deleteTiktok, setDeleteTiktok] = useState(false),
    [selectedTiktokId, setSelectedTiktokId] = useState(null);

  const resetFields = () => {
    setNewUser(userData);
  };

  const handleEditList = (e, record) => {
    setShow(true);
    setEdit(record);

    setNewUser((prevState) => ({
      ...prevState,
      tiktok: record.tiktok,
    }));
    setEditedStatus(record.status);
  };

  const handleClose = () => {
    setShow(false);
    resetFields();
    setEditedStatus("");
    setEdit("");
  };

  const getTiktoks = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/tiktoks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          client_id: Number(localStorage.getItem("client_id")),
        },
      });

      if (response.data?.tiktok.length) {
        setList(response?.data?.tiktok);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        setSettings({
          visible: true,
          tiktok: 0,
          instagram: 0,
          clientId: Number(localStorage.getItem("client_id")),
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTiktoks();
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleDelete = async (tiktokId) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/tiktoks/${tiktokId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setList(list.filter((record) => record.id !== tiktokId));
      toast.success("Tiktok account deleted successfully!");
      setDeleteTiktok(false);
    } catch (error) {
      toast.error("Error deleting tiktok account");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchUserDetails = async () => {
    const userConfirmed = window.confirm(
      "Please add at least 5 accounts before syncing. Do you want to continue anyway?"
    );

    if (!userConfirmed) {
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post('/api/tiktok/fetch-tiktok-user-details', {
        user_id: Number(localStorage.getItem("client_id"))
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(response.data.message);
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "An error occurred. Please try again later.";
      console.error(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const addTiktokAccountsAPICall = async () => {
    const { tiktok } = newUser;

    if (!tiktok) {
      toast.error("Please fill in the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      client_id: Number(localStorage.getItem("client_id")),
      tiktok,
    };

    try {
      if (edit) {
        await axiosInstance.put(
          `/api/tiktoks/${edit.id}`,
          {
            ...payload,
            status: editedStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axiosInstance.post(
          `/api/tiktoks`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setIsLoading(false);
      setEdit("");
      setEditedStatus("");
      setNewUser((prevState) => ({
        ...prevState,
        tiktok: "",
      }));
      setShow(false);
      getTiktoks();
      toast.success(`${edit ? "Updated" : "Created"} successfully`);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.status ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      console.error("Error while creating/updating:", error.response?.data || error);
    }
  };

  const addEditTiktokAccounts = () => {
    return (
      <>
        <Form.Group
          className="addToMoreList"
          controlId="exampleForm.ControlInput3"
        >
          <Form.Control
            type="text"
            autoFocus
            style={{ display: "inline-block" }}
            value={newUser.tiktok}
            onChange={(e) =>
              setNewUser((prevState) => ({
                ...prevState,
                tiktok: e.target.value,
              }))
            }
            required
            placeholder="Tiktok@id"
          />
        </Form.Group>

        {editedStatus && (
          <Form.Group className="mt-3">
            <select
              name="status"
              className="rolelistingDropdown creatuserListing form-select"
              value={editedStatus}
              onChange={(e) => setEditedStatus(e.target.value)}
            >
              <option disabled value="">
                Select Status
              </option>
              <option key={1} value="1">
                Active
              </option>
              <option key={0} value="0">
                In-Active
              </option>
            </select>
          </Form.Group>
        )}
      </>
    );
  };

  return (
    <div className=" clientLists userLists">
      <Toaster position="top-center" richColors />
      <CommonHeader
        dashboardName={"Tiktok Accounts"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {
          setShow(true);
        }}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="sync-tiktok-details">
            <button disabled={isLoading} className="creatrUserSubmit btn btn-primary" onClick={handleFetchUserDetails}>
              Sync TikTok Details
            </button>
          </div>
          <div className="userDetails">
            <div className="details mt-4">
              {list.length > 0 ? (
                <table
                  class="table table-striped"
                  style={{
                    height: "auto",
                    maxHeight: "30vh",
                    overflowX: "auto",
                  }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((record, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <Link to={`/Tiktok-UserDetails/${record?.id}`}>
                            {record.tiktok}
                          </Link>
                        </td>

                        <td>{Number(record.status) ? "Active" : "InActive"}</td>

                        <td>
                          <button
                            data-tooltip-id="Edit-button"
                            className="edit me-2"
                            onClick={(e) => handleEditList(e, record)}
                            style={{
                              padding: "4px 10px",
                              fontSize: "22px",
                            }}
                          >
                            <TbEdit />
                          </button>
                          <ReactTooltip
                            id="Edit-button"
                            place="bottom"
                            content="Edit tiktok Account"
                          />
                          {hasPermission(
                            user.permissions,
                            "delete-tiktok"
                          ) && (
                              <button
                                data-tooltip-id="delete-button"
                                className="delete edit"
                                style={{
                                  background: "red",
                                  color: "white",
                                  fontSize: "22px",
                                  padding: "4px 10px",
                                }}
                                onClick={() => {
                                  setDeleteTiktok(true);
                                  setSelectedTiktokId(record.id);
                                }}
                              >
                                <MdDelete />
                              </button>
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No Accounts found
                  </td>
                </tr>
              )}
            </div>
          </div>
          <div>
            {totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add / Edit Modal */}
      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <h4 className="create_user_heading">
            {edit?.tiktok ? `Update` : `Add`} Account
          </h4>
        }
        bodyContent={
          <>
            {addEditTiktokAccounts()}
          </>
        }
        footerContent={
          <>
            <Button
              variant="primary"
              className="creatrUserSubmit"
              onClick={addTiktokAccountsAPICall}
            >
              {edit?.tiktok ? `Update` : `Add`} Account
            </Button>
            <Button
              variant="secondary"
              className="creatrUserClose"
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        }
      />
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}

      {/* Delete Confirmation Modal */}
      <CustomModal
        show={deleteTiktok}
        handleClose={() => setDeleteTiktok(false)}
        headerContent={<h4>Confirm Delete</h4>}
        bodyContent={<p>Are you sure you want to delete this Tiktok account?</p>}
        footerContent={
          <Button
            variant="danger"
            onClick={() => handleDelete(selectedTiktokId)}
            disabled={isLoading}
          >
            {isLoading ? "Deleting..." : "Confirm"}
          </Button>
        }
      />
    </div>
  );
};

export default AddTiktokAccounts;
