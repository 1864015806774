import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link, useParams } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { userVideoDetails } from "../../../utils/constants";
import moment from "moment";
import CustomModal from "../common-components/customModal";
import Button from "react-bootstrap/Button";
import { returnBackButton } from "../../../utils/helperfunctions";
import { DateRangePicker } from "rsuite";
import { ImAttachment } from "react-icons/im";
import { hasPermission } from "../../../helperPermissionEditor";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { LoginContext } from "../../../App";
import { MultiSelect } from "react-multi-select-component";

const TikTokUserVideosDetails = () => {
  const [pageStart, setPageStart] = useState(1),
    [isLoading, setIsLoading] = useState(false),
    [userNotFound, setUserNotFound] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    { tiktokId } = useParams(),
    [videoDetails, setVideoDetails] = useState({}),
    [show, setShow] = useState(false),
    [details, setDetails] = useState([]),
    [date, setDate] = useState([]),
    [videoOption, setVideoOptions] = useState([]),
    [activeTagsList, setActiveTagsList] = useState([]),
    [record, setRecord] = useState({
      visible: false,
      tag: [],
    }),
    [selectedData, setSelectedData] = useState({
      edit: false,
      tag: [],
      videoId: "",
    }),
    [goingToAttach, setTagToAttach] = useState([]),
    [selectedTag, setSelectedTag] = useState([]),
    [selectedVideoId, setSelectedVideoId] = useState([]),
    { user } = useContext(LoginContext);

  const handleChange = (r) => {
    if (!r || !r?.length) {
      setDate([]);
      return;
    }

    const formatDate = [r[0], r[1]];
    setDate(formatDate);
  };

  useEffect(() => {
    getActiveTags();
  }, []);

  useEffect(() => {
    // if (currentPage > 1) {
    getVideoDetails(currentPage);
    // }
  }, [date, selectedTag, currentPage]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const getVideoDetails = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    let startDate = "";
    let endDate = "";

    if (date?.length) {
      startDate = moment(date[0]).format("YYYY-MM-DD");
    } else {
      startDate = "";
    }

    if (date?.length) {
      endDate = moment(date[1]).format("YYYY-MM-DD");
    } else {
      endDate = "";
    }

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      config.params = {
        page: page,
        video_id: selectedVideoId,
      };

      const response = await axiosInstance.post(
        `/api/tiktok/user/videos`,
        {
          tiktok_id: tiktokId,
          tags: selectedTag.map((tag) => tag.value),
          from: startDate,
          to: endDate,
        },
        config
      );

      if (response?.status === 200) {
        const { data, pagination } = response?.data;
        if (!selectedVideoId) {
          setVideoOptions(data.map((v) => v.video_id));
        }
        setVideoDetails(data);
        setTotalPages(pagination.last_page);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setVideoDetails([]);
        setTotalPages(1);
      }
      console.error("Error fetching roles:", error);
    }
    setIsLoading(false); // End loading
  };

  const getActiveTags = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/active-tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsLoading(false);
      if (response.status === 200) {
        const localActiveTags = response.data.tags?.map((tag) => ({
          label: tag.title,
          value: tag.id,
        }));
        setRecord({
          ...record,
          visible: true,
        });
        setActiveTagsList(localActiveTags);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching record for edit:", error);
    }
  };

  const attachTag = (video) => {
    let selectedTagIDs = [];
    if (video?.tag?.length) {
      const videoTags = new Set(video.tag.map((item) => item.title));
      selectedTagIDs = activeTagsList.filter((item) =>
        videoTags.has(item.label)
      );
    }

    if (selectedTagIDs?.length) {
      setSelectedData({
        edit: true,
        tag: selectedTagIDs,
        videoId: video.video_id,
      });

      setRecord({
        ...record,
        tag: selectedTagIDs,
      });
    } else {
      setSelectedData({
        ...selectedData,
        videoId: video.video_id,
      });
    }

    setRecord({
      ...record,
      visible: true,
    });
    setShow(true);
  };

  const attachTagAPI = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      video_id: selectedData?.videoId,
      tags: record.tag.map((t) => t.value.toString()) || selectedData?.tag,
    };
    try {
      await axiosInstance.post(`/api/attach-tag`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getVideoDetails();
      toast.success("Tag attached successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error attaching record");
      console.error("Error attaching tag:", error);
    }
  };
  const handleClose = () => {
    setRecord({
      visible: false,
      tag: [],
    });
    setSelectedVideoId("");
    setShow(false);
    setSelectedData({});
  };

  const showDetails = (index) => {
    let selectedRow;
    for (let i = 0; i <= videoDetails.length; i++) {
      if (i === index) {
        selectedRow = videoDetails[index];

        setDetails({
          caption: selectedRow?.caption,
          description: selectedRow?.description,
          hashtag: selectedRow?.hashtag?.split(", "),
        });
      }
    }
    setShow(true);
  };

  const handleTagsFilter = (value) => {
    if (value?.length < 1) {
      setSelectedTag([]);
      return;
    }
    setSelectedTag(value);
  };

  const handleAttachTag = (value) => {
    if (value?.length < 1) {
      setRecord({
        ...record,
        tag: [],
      });
      return;
    }

    setRecord({
      ...record,
      tag: value,
    });
  };

  const getTagsFilter = () => {
    return (
      <MultiSelect
        options={activeTagsList}
        value={selectedTag}
        onChange={handleTagsFilter}
        labelledBy="Select"
        closeMenuOnSelect={false}
        className="multi-select-container"
      />
    );
  };
  const getActiveBadges = (video) => {
    return video?.tag?.map((t, i) => (
      <Badge
        bg="success"
        key={i}
        style={{ display: "block", marginBottom: "5px" }}
      >
        {t.title}
      </Badge>
    ));
  };
  const getFormUI = () => {
    return record.visible ? (
      // Attach tag
      <MultiSelect
        options={activeTagsList}
        value={record.tag}
        onChange={handleAttachTag}
        labelledBy="Select"
        className="multi-select-container"
      />
    ) : (
      <div style={{ dipslay: "block !important" }}>
        <h6> Caption</h6>
        <p>{details?.caption}</p>
        <h6> Description</h6>
        <p>{details?.description}</p>
        <h6> Hash tags</h6>
        <p>
          {details?.hashtag?.map((tag, i) => (
            <span
              key={i}
              class="badge"
              style={{
                background: "aqua",
                marginRight: "10px",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              {tag}
            </span>
          ))}
        </p>
      </div>
    );
  };

  return (
    <div className="userLists">
      {userNotFound.status === 403 ? (
        <div
          style={{
            fontSize: "30px",
            position: "fixed",
            top: "50%",
            left: "60vh",
          }}
          className="user-not-found-message container"
        >
          {userNotFound.message}
        </div>
      ) : (
        <>
          <div className="mt-1">
            <div className="userFetchData">
              <div className="userDetails mt-2">
                <div
                  style={{ backgroundColor: "#fff" }}
                  className="d-flex align-content-center align-items-center"
                >
                  {returnBackButton()}

                  <h1 className="fw-bold m-0 p-3 text-center rounded">
                    {videoDetails?.tiktok &&
                      videoDetails?.tiktok?.charAt(0).toUpperCase() +
                        videoDetails?.tiktok?.slice(1)}{" "}
                    Video Details
                  </h1>
                  <DateRangePicker
                    style={{ marginLeft: "30px", maxHeight: "50px" }}
                    value={date}
                    onChange={handleChange}
                    placeholder="Select Date Range"
                  />
                  <div style={{ marginLeft: "10px", minWidth: "300px" }}>
                    {getTagsFilter()}
                  </div>
                </div>
                <div className="videoDetails mt-3">
                  <table className="user-table">
                    <thead>
                      <tr>
                        {userVideoDetails?.map((heading) => (
                          <th>{heading}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="6">Loading...</td>
                        </tr>
                      ) : videoDetails?.length ? (
                        videoDetails?.map((video, index) => (
                          <tr
                            key={video?.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#f2f2f2",
                            }}
                          >
                            <td>
                              <Link
                                to={`/Tiktok-video-statistics/${video?.video_id}`}
                              >
                                {video?.video_id}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/Tiktok-video/sound/${video?.sound_id}`}
                              >
                                {video?.sound_id}
                              </Link>
                            </td>
                            <td class="text-center">
                              <a
                                href={video?.video_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                url
                              </a>
                            </td>
                            <td>{video?.views}</td>
                            <td>{video?.likes}</td>
                            <td>{video?.shares}</td>
                            <td>{video?.saved}</td>
                            <td>{video?.comments}</td>
                            <td>{video?.create_time}</td>
                            <td>
                              <Toaster position="top-center" richColors />
                              <button
                                data-tooltip-id="view-button"
                                className="edit me-2"
                                onClick={() => attachTag(video)}
                                style={{
                                  padding: "4px 10px",
                                  fontSize: "22px",
                                }}
                              >
                                <ImAttachment />
                              </button>
                              <ReactTooltip
                                id="view-button"
                                place="bottom"
                                content="View Details"
                              />
                            </td>
                            <td>
                              <button
                                variant="primary"
                                onClick={() => showDetails(index)}
                              >
                                Details
                              </button>
                            </td>
                            <td>{getActiveBadges(video)}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No Record found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {totalPages > 1 && (
                <div className="pagination">
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageStart={pageStart}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <h4 className="create_user_heading mb-2">
            {record.visible ? "Attach Tag" : "Details"}
          </h4>
        }
        bodyContent={getFormUI()}
        footerContent={
          <div className="d-flex justify-content-center align-items-center">
            {record.visible && (
              <Button
                variant="primary"
                className="creatrUserSubmit me-3"
                onClick={attachTagAPI}
              >
                Attach Tag
              </Button>
            )}
            <Button
              variant="secondary"
              className="creatrUserClose"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default TikTokUserVideosDetails;
