import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from "./DaysFilter";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";
import { backgroundColors, colors } from "../../../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokFollowerGrowth = () => {
  const [selectedDays, setSelectedDays] = useState([
    subDays(new Date(), 30 - 1),
    new Date(),
  ]),
    [followerGrowthData, setFollowerGrowthData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchFollowerGrowth = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/dashboard/daily-follower-growth",
        {
          days: null,
          from,
          to,
          limit: 30,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFollowerGrowthData(response.data.data);

      if (!response.data.data.length) {
        setNoData(true);
      }

      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch daily followers gained/loss data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFollowerGrowth();
  }, [selectedDays]);

  const generateChartData = () => {
    const labels = followerGrowthData.map((item) =>
      moment(item.date).format("DD MMM, YY")
    );
    const positiveValues = followerGrowthData.map((item) =>
      item.gained >= 0 ? item.gained : 0
    );
    const negativeValues = followerGrowthData.map((item) =>
      item.loss >= 0 ? item.loss : 0
    );

    const data = {
      labels,
      datasets: [
        {
          label: "Daily Followers Gained",
          data: positiveValues,
          borderColor: colors.blue,
          backgroundColor: backgroundColors.light_blue,
          tension: 0.4,
          fill: true,
          pointBackgroundColor: colors.blue,
          pointBorderColor: "#fff",
          pointBorderWidth: 2,
          borderWidth: 2,
          yAxisID: "y",
        },
        {
          label: "Daily Followers Loss",
          data: negativeValues,
          borderColor: colors.red,
          backgroundColor: backgroundColors.red,
          tension: 0.4,
          fill: true,
          pointBackgroundColor: colors.red,
          pointBorderColor: "#fff",
          pointBorderWidth: 2,
          borderWidth: 2,
          yAxisID: "y",
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        type: "linear",
        position: "left",
        beginAtZero: true,
      },
      x: {
        type: "category",
        labels: followerGrowthData.map((item) =>
          moment(item.date).format("DD MMM, YY")
        ),
      },
    },
  };

  return (
    <div className="follower-growth-container">
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />
      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <ErrorPage refetch={fetchFollowerGrowth} />
      ) : (
        <div className="chart-wrapper">
          {followerGrowthData.length > 0 ? (
            <>
              <h5 className="chart-title">
                Daily Followers Gained/Loss &nbsp;
                <span
                  data-tooltip-id="help-tooltip"
                  style={{ fontSize: "26px" }}
                >
                  <IoIosHelpCircleOutline />
                </span>
                <ReactTooltip
                  id="help-tooltip"
                  place="bottom"
                  content="Helping Text"
                />
              </h5>
              <FullscreenWrapper>
                <Bar data={generateChartData()} options={options} />
              </FullscreenWrapper>
            </>
          ) : (
            <p>No follower growth data available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TikTokFollowerGrowth;
