import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from "./DaysFilter";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import HashTagFilter from "./HashTagFilter";
import { colors } from "../../../../utils/constants";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TikTokHashtagPerformance = () => {
  const [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [selectedLimit, setSelectedLimit] = useState(15),
    [hashtagPerformanceData, setHashtagPerformanceData] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchHashtagPerformance = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/dashboard/hashtag-performance",
        { days: null, from, to, limit: selectedLimit },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHashtagPerformanceData(response.data.data);

      if (!response.data.data.length) {
        setNoData(true);
      }

      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch TikTok hashtag performance data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHashtagPerformance();
  }, [selectedDays, selectedLimit]);

  const abbreviateLabel = (label) => {
    const maxLength = 13;
    return label.length > maxLength
      ? `${label.substring(0, maxLength)}...`
      : label;
  };

  const generateChartData = () => {
    const labels = hashtagPerformanceData.map((item) =>
      abbreviateLabel(item.hashtag)
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Total Views",
          data: hashtagPerformanceData.map((item) => item.total_views),
          backgroundColor: colors.sea_green,
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const onChangeLimit = (value) => {
    setSelectedLimit(value);
  };

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        padding: 10,
        callbacks: {
          label: function (tooltipItem) {
            const originalLabel =
              hashtagPerformanceData[tooltipItem.dataIndex].hashtag;
            const value = tooltipItem.raw;
            return [`Hashtag: ${originalLabel}`, `Total Views: ${value}`];
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 30,
          autoSkip: true,
          maxTicksLimit: 20,
          callback: function (value, index, values) {
            return abbreviateLabel(this.getLabelForValue(value));
          },
        },
      },
    },
  };

  return (
    <div className="hashtag-performance-container">
      <div className="filter-container p-3">
        <div class="row">
          <div class="col-md-6">
            <DaysFilter selectedDays={selectedDays} onChange={onChange} />
          </div>
          <div class="col-md-6 limit-filter">
            <HashTagFilter
              selectedLimit={selectedLimit}
              onChange={onChangeLimit}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <div className="error-overlay">
          <ErrorPage refetch={fetchHashtagPerformance} />
        </div>
      ) : (
        <div className="chart-wrapper">
          {hashtagPerformanceData.length > 0 ? (
            <>
              <h5 className="chart-title">
                TikTok Hashtag Performance &nbsp;
                <span
                  data-tooltip-id="help-tooltip"
                  style={{ fontSize: "26px" }}
                >
                  <IoIosHelpCircleOutline />
                </span>
                <ReactTooltip
                  id="help-tooltip"
                  place="bottom"
                  content="Helping Text"
                />
              </h5>
              <FullscreenWrapper>
                <Bar data={generateChartData()} options={chartOptions} />
              </FullscreenWrapper>
            </>
          ) : (
            <p>No hashtag performance data available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TikTokHashtagPerformance;
