import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import TikTokAccountFilters from "./component/TikTokAccountFilters";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { LuFilter } from "react-icons/lu";
import { Button } from "react-bootstrap";
import FullscreenWrapper from "../common-components/FullScreenWrapper";
import { colors, backgroundColors } from "../../../utils/constants";
import { Bar } from "react-chartjs-2";
import moment from "moment/moment";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";

const TikTokIndex = () => {
  const [accounts, setAccounts] = useState([]),
    [isLoading, setLoading] = useState(false),
    [error, setError] = useState(null),
    [pagination, setPagination] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [filters, setFilters] = useState({
      client_id: null,
      follower_count_from: "",
      follower_count_to: "",
      following_count_from: "",
      following_count_to: "",
      total_favorited_from: "",
      total_favorited_to: "",
      visible_videos_count_from: "",
      visible_videos_count_to: "",
      from: "",
      to: "",
      sort_by: "average_views",
      order_by: "desc",
    }),
    [clients, setClients] = useState([]),
    [record, setRecord] = useState({
      visible: false,
      clients: [],
    }),
    [isPopoverOpen, setIsPopoverOpen] = useState(false),
    clickMeButtonRef = useRef(),
    [sortConfig, setSortConfig] = useState({ key: null, direction: null }),
    [chartsData, setChartData] = useState({
      views: [],
      interactions: [],
      engagements: [],
      names: [],
      images: [],
    });

  const fetchTikTok = async (page = 1) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        `/api/tiktok/accounts?page=${page}`,
        filters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const localViews = [],
        localInteractions = [],
        localEngagement = [],
        localNames = [];

      const localAccounts = response.data.data.map((account) => {
        localViews.push(account.average_views);
        localInteractions.push(account.average_interactions);
        localEngagement.push(account.average_engagement);
        localNames.push(account.tiktok_account.nickname);
        return {
          ...account.tiktok_account,
          views: Number(account.average_views),
          interactions: Number(account.average_interactions),
          engagement: Number(account.average_engagement),
        };
      });
      const images = localAccounts.map((img) => img.avatar);

      setChartData({
        views: localViews,
        interactions: localInteractions,
        engagements: localEngagement,
        names: localNames,
        images,
      });

      setAccounts(localAccounts);
      const { pagination: apiPagination } = response.data;
      setPagination(apiPagination);
      setCurrentPage(apiPagination.current_page);
      setTotalPages(apiPagination.last_page);
      setLoading(false);
    } catch (err) {
      setAccounts([]);
      setError("Failed to fetch TikTok Accounts");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTikTok(currentPage);
  }, [currentPage, filters]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/dashboard/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const localClients = response.data.clients?.map((client) => ({
          name: client.name,
          id: client.id,
        }));
        setRecord({
          ...record,
          visible: true,
        });
        setClients(localClients);
      }
    } catch (error) {
      console.error("Error fetching record for edit:", error);
    }
  };

  const sortedAccounts = useMemo(() => {
    if (!sortConfig.key) return accounts;

    const sortedData = [...accounts];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [accounts, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };

  // Charts
  const generateChartData = (label, content) => {
    const data = {
      labels: chartsData.names.slice(0, 5),
      datasets: [
        {
          label: label,
          data: content.slice(0, 5),
          backgroundColor: Object.values(colors),
          borderColor: Object.values(backgroundColors),
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: Object.values(colors),
          image: chartsData.images.slice(0, 5),
        },
      ],
    };
    return data;
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  const imagePlugin = {
    id: "imagePlugin",
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;

      data.datasets[0].image.forEach((image, index) => {
        const meta = chart.getDatasetMeta(0).data[index];

        if (!meta) return;

        const xPos = meta.x - 15;
        const yPos = meta.y - 35;
        const newImage = new Image();
        const radius = 17;

        newImage.src = image;
        newImage.onload = () => {
          ctx.save();
          ctx.beginPath();
          ctx.arc(xPos + radius, yPos + radius, radius, 0, Math.PI * 2);
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(newImage, xPos, yPos, 50, 50);
          ctx.restore();
        };
      });
    },
  };

  return (
    <div className="clientLists userLists">
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}

      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <div style={{ display: "flex" }}>
              <h1 className="bold fs-4 mb-0 me-4">TikTok Account</h1>
              <div>
                <Popover
                  isOpen={isPopoverOpen}
                  positions={["bottom"]}
                  padding={10}
                  onClickOutside={() => setIsPopoverOpen(false)}
                  ref={clickMeButtonRef}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor={"blue"}
                      arrowSize={10}
                      arrowStyle={{ opacity: 0.7 }}
                      className="popover-arrow-container"
                      arrowClassName="popover-arrow"
                    >
                      <div className="d-flex align-content-center align-items-center bg-white">
                        <TikTokAccountFilters
                          clients={clients}
                          onFilterChange={handleFilterChange}
                        />
                      </div>
                    </ArrowContainer>
                  )}
                >
                  <Button
                    variant="primary"
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                  >
                    Filters
                  </Button>
                </Popover>
              </div>
            </div>

            {!isLoading && (
              <div className="row mt-3 p-0">
                <div className="col-4 p-0">
                  <FullscreenWrapper>
                    <Bar height={250}
                      data={generateChartData("Average View", chartsData.views)}
                      options={options}
                      plugins={[imagePlugin]}
                    />
                  </FullscreenWrapper>
                </div>
                <div className="col-4 p-0">
                  <FullscreenWrapper>
                    <Bar height={250}
                      data={generateChartData(
                        "Average Interactions",
                        chartsData.interactions
                      )}
                      options={options}
                      plugins={[imagePlugin]}
                    />
                  </FullscreenWrapper>
                </div>
                <div className="col-4 p-0">
                  <FullscreenWrapper>
                    <Bar height={250}
                      data={generateChartData(
                        "Engagement",
                        chartsData.engagements
                      )}
                      options={options}
                      plugins={[imagePlugin]}
                    />
                  </FullscreenWrapper>
                </div>
              </div>
            )}

            <div className="details mt-4">
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Avatar</th>
                    <th>Unique ID</th>
                    <th>Nickname</th>
                    <th>Client</th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("follower_count")}
                    >
                      Follower Count &nbsp;
                      {getArrow("follower_count")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("following_count")}
                    >
                      Following Count &nbsp;
                      {getArrow("following_count")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("total_favorited")}
                    >
                      Total Favorited &nbsp;
                      {getArrow("total_favorited")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("visible_videos_count")}
                    >
                      Visible Videos &nbsp;
                      {getArrow("visible_videos_count")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("views")}
                    >
                      Avg. View &nbsp;
                      {getArrow("views")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("interactions")}
                    >
                      Avg. Interactions &nbsp;
                      {getArrow("interactions")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("engagement")}
                    >
                      Engagement &nbsp;
                      {getArrow("engagement")}
                    </th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAccounts.length > 0 ? (
                    sortedAccounts.map((account, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={account.avatar}
                            alt={account.nickname}
                            width="50"
                            height="50"
                            style={{ borderRadius: "50%" }}
                          />
                        </td>
                        <td>
                          <Link to={`/Tiktok-statistics/${account?.tiktok_id}`}>
                            {account.unique_id}
                          </Link>
                        </td>
                        <td>{account.nickname}</td>
                        <td>{account.client}</td>
                        <td>{account.follower_count}</td>
                        <td>{account.following_count}</td>
                        <td>{account.total_favorited}</td>
                        <td>{account.visible_videos_count}</td>

                        <td>{account.views}</td>
                        <td>{account.interactions}</td>
                        <td>{account.engagement}</td>

                        <td style={{ maxWidth: "100px" }}>
                          {moment(account.created_at).format("MMM Do YY")}
                        </td>
                        <td style={{ maxWidth: "100px" }}>
                          {moment(account.updated_at).format("MMM Do YY")}
                        </td>
                        <td>
                          <a
                            href={account.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span data-tooltip-id="noedit-button">
                              <FaExternalLinkAlt />
                            </span>
                            <ReactTooltip
                              id="noedit-button"
                              place="bottom"
                              content="go to View Profile"
                            />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={11} className="text-center">
                        No accounts found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {accounts.length > 0 && totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TikTokIndex;
