import React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

const DeleteModal = ({ toggleState, toggleHandler, apiCallHandleronClick }) => {
  return toggleState ? (
    <div className="custom-alert">
      <Alert show={toggleState} variant="warning">
        <Alert.Heading>Warning</Alert.Heading>
        <p>Are you sure you want to delete this Record?</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={toggleHandler} variant="warning">
            Cancel
          </Button>
          <Button
            className="delete edit ms-2"
            style={{
              background: "red",
              color: "white",
            }}
            onClick={apiCallHandleronClick}
            variant="warning"
          >
            Delete
          </Button>
        </div>
      </Alert>
    </div>
  ) : (
    ""
  );
};

export default DeleteModal;
