import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: apiUrl,
});

const token = localStorage.getItem("token");
const refreshToken = async () => {
  if (token) {
    try {
      const response = await axiosInstance.post("/api/refresh");
      const { token, expires_in } = response.data;
      localStorage.setItem("token", token);
      scheduleTokenRefresh(expires_in);
    } catch (error) {
      console.error("Failed to refresh token:", error);
    }
  }
};
const scheduleTokenRefresh = (expiresIn) => {
  const refreshTime = (expiresIn - 60) * 1000;
  setTimeout(refreshToken, refreshTime);
};

const initializeTokenRefresh = () => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      const { exp } = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      const expiresIn = exp - currentTime;
      if (expiresIn > 0) {
        scheduleTokenRefresh(expiresIn);
      } else {
        refreshToken();
      }
    } else {
      refreshToken();
    }
  } catch (error) {
    console.error("Failed to initialize token:", error);
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
initializeTokenRefresh();

export default axiosInstance;
