import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
// import "../../pages/pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../../../loader/CommonLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../../common-components/CommonHeader";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../../App";

const FreelancerClientsListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [data, setData] = useState([]),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    navigate = useNavigate();

  const getListing = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `/api/dashboard/client`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const apiData = response.data.client;
      const clients = Array.isArray(apiData) ? apiData : [apiData];

      setData(clients);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getListing();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const getTableUI = () => {
    return (
      <div className="details mt-4">
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Phone No.</th>
              <th>Status</th>
              <th>Address</th>
              <th>Show</th>
              <th>Show</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.phone}</td>
                  <td>{row.status}</td>
                  <td>{row.address}</td>
                  <td>
                    <Toaster position="top-center" richColors />
                    <button
                      data-tooltip-id="view-button"
                      className="edit me-2"
                      onClick={() => {
                        navigate(`/showBooks/${row?.id}`);
                      }}
                    >
                      Books
                    </button>
                    <ReactTooltip
                      id="view-button"
                      place="bottom"
                      content="Show Books"
                    />
                  </td>
                  <td>
                    <Toaster position="top-center" richColors />
                    <button
                      data-tooltip-id="view-button"
                      className="edit me-2"
                      onClick={() => {
                        navigate(`/showText/${row?.id}`);
                      }}
                    >
                      Text
                    </button>
                    <ReactTooltip
                      id="view-button"
                      place="bottom"
                      content="Show Text"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Freelancer Clients"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {}}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
        </div>
      </div>

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default FreelancerClientsListing;
